import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';

import video1 from '../videos/prolet_350z.mov'
import video1bg from '../videos/prolet_350z.jpg'

import goodset from '../images/goods/nissan_350z_set.jpg'
import good1 from '../images/goods/nissan_350z_1.jpg'
import good2 from '../images/goods/nissan_350z_2.jpg'
import good3 from '../images/goods/nissan_350z_3.jpg'


const Category = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    return (
    <>
        <Header/>

        <div className="index-section" style={{ position: 'relative' }}>
            <video autoPlay loop muted className="index-content-video2"><source src={video1} type="video/mp4"/></video>
            <div className="index-content-video2-bg"/>
            <div className="index-content-header fadeout">
                
                <p style={{fontSize: '48px'}}><b>NISSAN 350Z / FAIRLADY Z</b></p>

                <p className="noselect" style={{fontSize: '40px', marginTop: '150px', color:'#ffffff4d'}}><b>2002-2006</b></p>
                <div className="category-content">
                    <Link className="item" to="#">
                        <img src={goodset} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">KIT A</p>
                            <p className="text_right">$500/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good1} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">FRONT SPLITTER A</p>
                            <p className="text_right">$150/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good2} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">SIDE SKIRTS</p>
                            <p className="text_right">$250/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good3} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">REAR APRONS</p>
                            <p className="text_right">$100/set</p>
                        </div>
                    </Link>
                </div>

                <p className="noselect" style={{fontSize: '40px', marginTop: '50px', color:'#ffffff4d'}}><b>2007-2008</b></p>
                <div className="category-content">
                    <Link className="item" to="#">
                        <img src={goodset} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">KIT B</p>
                            <p className="text_right">$500/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good1} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">FRONT SPLITTER B</p>
                            <p className="text_right">$150/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good2} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">SIDE SKIRTS</p>
                            <p className="text_right">$250/set</p>
                        </div>
                    </Link>
                    <Link className="item" to="#">
                        <img src={good3} width='280px' draggable="false"/>
                        <div className="title">
                            <p className="text_left">REAR APRONS</p>
                            <p className="text_right">$100/set</p>
                        </div>
                    </Link>
                </div>

                <Footer/>
            </div>

            <div className="index-content-footer fadeout" style={{zIndex: '-1'}}></div>
        </div>
    </>
    );
};
export default Category;