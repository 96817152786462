import React, { useEffect } from 'react';
import { Link, Navigate } from 'react-router-dom';
import { useCookies } from 'react-cookie';
import { useTranslation } from 'react-i18next';
import {ShowError, ShowSuccess, ShowInfo} from './Modules/Popups';

import fireflash1 from '../images/goods/flashfire1.png';

const Header = () => {
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    function Account() {
        if (cookies.token != null) {
            //Переходим в профиль
            window.location.href = '/profile';
        }
        else {
            window.location.href = '/auth';
        }
    }

    let lastTab = "";
    function IsHovered(tab) {
        //Скрываем прошлую вкладку
        if (lastTab != "" && lastTab != tab) {
            let button = document.getElementById("button-" + lastTab);
            let element = document.getElementById("tab-" + lastTab);
            if (button) {
                if (button.classList.contains("active"))
                    button.classList.remove("active");
            }
            if (element) {
                if (element.classList.contains("active"))
                    element.classList.remove("active");
            }
        }

        //Показываем новую вкладку
        lastTab = tab;
        let button = document.getElementById("button-" + tab);
        let element = document.getElementById("tab-" + tab);
        let elementEnd = document.getElementById("tab-close");
        if (button) {
            if (!button.classList.contains("active"))
                button.classList.add("active");
        }
        if (element) {
            if (!element.classList.contains("active"))
                element.classList.add("active");
        }
        if (elementEnd) {
            if (!elementEnd.classList.contains("active"))
                elementEnd.classList.add("active");
        }
    }
    function CloseTabs() {
        let button = document.getElementById("button-" + lastTab);
        let element = document.getElementById("tab-" + lastTab);
        let elementEnd = document.getElementById("tab-close");
        if (button && element && elementEnd) {
            if (button.classList.contains("active"))
                button.classList.remove("active");
            if (element.classList.contains("active"))
                element.classList.remove("active");
            if (elementEnd.classList.contains("active"))
                elementEnd.classList.remove("active");
        }
        lastTab = "";
    }

    return (
    <>
        <div className="header-main">
            <div className="header-container left">
                <Link to="/" className="header-button" style={{fontSize: '20px'}}>2wide</Link>
            </div>

            <div className="header-container">
                <Link id="button-main" to="/" className="header-button noselect" onMouseEnter={() => IsHovered("main")}>Главная</Link>
                <a id="button-goods" className="header-button noselect" onMouseEnter={() => IsHovered("goods")}>Категории</a>
                <a id="button-info" className="header-button noselect" onMouseEnter={() => IsHovered("info")}>Информация</a>
            </div>

            <div className="header-container right">
                <a id="button-help" className="header-button icon"><i className="icon question"></i></a>
                <a id="button-language" className="header-button icon" onClick={() => IsHovered("language")}><i className="icon globe"></i></a>
                <a className="header-button icon" onClick={() => Account()}><i className="icon user"></i></a>
            </div>
        </div>

        <div id="tab-main" className="header-page"></div>

        <div id="tab-goods" className="header-page">
            {/*<a className="header-page-title">Nissan</a><br/>
            <div className="header-page-container2">
                <Link className="header-container-item" to="/products/#">
                    <img src={fireflash1} width='60px'/>
                    <p style={{color:'white'}}>350Z 02-06</p>
                </Link>
                <Link className="header-container-item" to="/products/#">
                    <img src={fireflash1} width='60px'/>
                    <p style={{color:'white'}}>350Z 07-08</p>
                </Link>
            </div>*/}

            <div className="header-page-container">
                <div className="header-container-item2">
                    <p className="title">Nissan</p>
                    <Link className="button" to="/category/Nissan_350Z">350Z<p className="desc">'03-'08</p></Link>
                    <Link className="button-inactive" to="#">Silvia S13<p className="desc">'88-'94</p></Link>
                    <Link className="button-inactive" to="#">Silvia S14<p className="desc">'93-'98</p></Link>
                    <Link className="button" to="/category/Nissan_Silvia_S15">Silvia S15<p className="desc">'99-'02</p></Link>
                </div>
                <div className="header-container-item2">
                    <p className="title">Toyota</p>
                    <Link className="button-inactive" to="#">GR86<p className="desc">'21-'25</p></Link>
                </div>
            </div>

            <br/>
        </div>

        <div id="tab-info" className="header-page">
            <div className="header-page-container">
                <div className="header-container-item2">
                    <p className="title">Основное</p>
                    <p className="link">Что-то такое</p>
                </div>
                <div className="header-container-item2">
                    <p className="title">О нас</p>
                    <p className="link">Что-то такое</p>
                </div>
                <div className="header-container-item2">
                    <p className="title">Социальные сети</p>
                    <p className="link">Telegram</p>
                    <p className="link">Instagram</p>
                </div>
            </div>
            <br/>
        </div>

        <div id="tab-language" className="header-page">
            <div className="header-page-container">
                <div className="header-container-item2">
                    <p className="title">North America</p>
                    <p className="button">United States<p className="desc">English</p></p>
                    <p className="button">Canada<p className="desc">English</p></p>
                </div>
                <div className="header-container-item2">
                    <p className="title">Asia Pacific</p>
                    <p className="button">Australia<p className="desc">English</p></p>
                    <p className="button">New Zealand<p className="desc">English</p></p>
                </div>
                <div className="header-container-item2">
                    <p className="title">Europe</p>
                    <p className="button">Russian Federation<p className="desc">Русский</p></p>
                </div>
            </div>
            <br/>
        </div>

        <div id="tab-close" className="header-page-end" onMouseEnter={() => CloseTabs()}></div>
    </>
    );
};
export default Header;