import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';


const Footer = () => {

    return (
    <footer className="footer fadeout">
        <div className="header-page-container2">
            <div className="header-container-item2">
                <p className="title">Основное</p>
                <p className="link">Что-то такое</p>
            </div>
            <div className="header-container-item2">
                <p className="title">О нас</p>
                <p className="link">Что-то такое</p>
            </div>
            <div className="header-container-item2">
                <p className="title">Социальные сети</p>
                <p className="link">Telegram</p>
                <p className="link">Instagram</p>
            </div>
        </div>
        <a style={{fontSize: '14px', color: '#999'}}>© All Rights Reserved.<br/>contact@2wide.ru</a><br/><br/>

    </footer>
    );
};
export default Footer;