import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';

import video1 from '../videos/prolet_350z.mov'
import video1bg from '../videos/prolet_350z.jpg'

const Index = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    return (
    <>
        <Header/>

        <div className="index-section" style={{ position: 'relative', overflow: 'hidden', backgroundImage: `url(${video1bg})` }}>
            <video autoPlay loop muted className="index-content-video"><source src={video1} type="video/mp4"/></video>
            <div className="index-content-video-bg" />
            <div className="index-content-header fadeout">
                <p style={{fontSize: '48px'}}><b>NISSAN 350Z / FAIRLADY Z</b></p>
                {/*<p style={{marginTop: '-20px'}}>КОМПЛЕКТ АЭРОДИНАМИЧЕСКИХ ЭЛЕМЕНТОВ</p>*/}
                <Link className="index-content-button noselect" to="/category/Nissan_350Z">ПЕРЕЙТИ К ТОВАРАМ ДЛЯ 350Z</Link>
            </div>
            <div className="index-content-footer fadeout"></div>
        </div>

        <Footer/>
    </>
    );
};
export default Index;