import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import Header from './Header';
import Footer from './Footer';

import video1 from '../videos/video1.mov'
import video1bg from '../videos/video1.jpg'

const Auth = () => {
    const { t, i18n } = useTranslation(); const changeLanguage = (language) => { i18n.changeLanguage(language); };

    return (
    <>
        <Header/>

        <div className="index-section" style={{ position:'relative', overflow:'hidden', backgroundImage:`url(${video1bg})` }}>
            <video autoPlay loop muted className="index-content-video"><source src={video1} type="video/mp4"/></video>
            <div style={{ position:'absolute', top:'0', left:'0', width:'100%', height:'100%', backgroundColor:'rgba(0, 0, 0, 0.8)' }} />
            <div className="index-content-header fadeout">
                <div style={{width:'250px', marginLeft:'auto', marginRight:'auto'}}>
                    <p style={{fontSize:'32px'}}>Авторизация</p>
                    <input style={{width:'230px'}} type="email" placeholder="E-mail"/>
                    <br/>
                    <input style={{width:'230px'}} type="password" placeholder="Пароль"/>
                    <br/><br/>
                    <button style={{width:'255px'}}>Войти</button>
                    <br/><br/>
                    <Link style={{width:'100%', color:'#ffffffdf'}} to="/auth/recovery">Проблемы со входом?</Link>
                </div>
            </div>

            <div className="index-content-footer fadeout"></div>
        </div>
    </>
    );
};
export default Auth;